import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint } from 'src/theme/grid'

const ImageBox = styled.div`
  display: none;
  width: 50%;
  ${breakpoint.sm`
    display: grid;
  `}
`

const AboutBoxComponent = ({ className, text, fluid }) => (
  <div className={className}>
    <div>
      <span>{text}</span>
    </div>
    <ImageBox>
      <Img fluid={fluid} />
    </ImageBox>
  </div>
)

export const AboutBox = styled(AboutBoxComponent)`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  & > div:first-of-type { 
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: ${({ greenBg }) => (greenBg ? '#7dc37a' : 'white')};
    ${({ mobileGray }) =>
      mobileGray &&
      css`
        background-color: #f5f5f3;
        ${breakpoint.sm`
        background-color: white;
        `}
      `}
    color: ${({ greenBg }) => (greenBg ? 'white' : 'rgb(87, 92, 88)')};
    box-sizing: border-box;
    padding: 2.4rem 5%;
    font-weight: 400;
    font-size: 1.2rem;
    width: 100%;
    ${breakpoint.sm`
      padding: 2.2rem;
      width: 50%;
    `}
  }
`
