import React from 'react'
import { graphql } from 'gatsby'
import { map, path } from 'ramda'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import routes from 'src/utils/routes'
import LogoUrl from 'src/assets/logo-bright.png'

import { Button } from 'src/components/atoms/Buttons'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

import {
  StatisticsSection,
  ButtonsRow,
  CircleRow,
  Circle,
  CircleText,
  CircleNumber,
  MobileUserTitle,
  BannerSocial,
  Logo
} from 'src/components/static/home'
import { MainTitle } from 'src/components/static/common'
import { AboutBox } from 'src/components/static/about'

const About = ({
  data: {
    allFile: { edges }
  }
}) => {
  const intl = useIntl()

  const backgroundImages = map(
    img => path(['node', 'childImageSharp', 'fluid'], img),
    edges
  )

  return (
    <ThemeProvider>
      <Layout>
        <MainTitle>{intl.formatMessage({ id: 'aboutUs.title' })}</MainTitle>
        <AboutBox
          greenBg
          text={intl.formatMessage({ id: 'aboutUs.text1' })}
          fluid={backgroundImages[0]}
        />
        <AboutBox
          direction='row-reverse'
          text={intl.formatMessage({ id: 'aboutUs.text2' })}
          fluid={backgroundImages[1]}
        />
        <AboutBox
          mobileGray
          text={intl.formatMessage({ id: 'aboutUs.text3' })}
          fluid={backgroundImages[2]}
        />
        <AboutBox
          direction='row-reverse'
          text={intl.formatMessage({ id: 'aboutUs.text4' })}
          fluid={backgroundImages[3]}
        />
        <StatisticsSection>
          <CircleRow>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.trust.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.trust.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.trust.description' />
              </CircleText>
            </Circle>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.offers.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.offers.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.offers.description' />
              </CircleText>
            </Circle>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.targetsReached.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.targetsReached.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.targetsReached.description' />
              </CircleText>
            </Circle>
          </CircleRow>
        </StatisticsSection>
        <BannerSocial>
          <Logo src={LogoUrl} alt='logo' />
          <MobileUserTitle>
            <FormattedMessage id='homePage.joinSectionTitle' />
          </MobileUserTitle>
          <ButtonsRow>
            <Link to={routes.SIGN_UP}>
              <Button active>
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
          </ButtonsRow>
        </BannerSocial>
        <EUFinancingDialog />
      </Layout>
      <EuFinancingLogoLarge />
    </ThemeProvider>
  )
}

export const query = graphql`
  {
    allFile(sort: { fields: name }, filter: { name: { regex: "/about/" } }) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            fluid(quality: 100, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default About
